import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import EventList from '../Components/EventList';

const Event = () => {
  return (
    <>
      <div className="edgtf-woocommerce-page edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />

            {/* new html start */}
            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className=" clearfix pt-3">
                    {/* edgtf-container-inner this class ad and remove */}
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="pb-4">
                            <EventList />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* new html end */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Event;

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer className="edgtf-page-footer">
        <div className="edgtf-footer-top-holder border-dis">
          <div className="edgtf-footer-top-inner edgtf-grid pb-3 pt-lg-5 pt-3">
            <div className="edgtf-grid-row edgtf-footer-top-alignment-left">
              <div className="edgtf-column-content edgtf-grid-col-7 col-12">
                <div
                  id="text-3"
                  className="widget edgtf-footer-column-3 widget_text"
                >
                  <h5 className="edgtf-widget-title mb-0">CONTACT US</h5>
                  <div className="con-text">
                    <div className="textwidget">
                      <p className="first-p d-none">
                        <a to="mailto:partypass2024@gmail.com">
                          Partypass2024@gmail.com
                        </a>
                        <br />
                        <a to="tel:+1 9175582832">+1 9175582832</a>
                      </p>
                    </div>
                    <div
                      id="text-11"
                      className="widget edgtf-footer-column-3 widget_text"
                    >
                      <div className="textwidget">
                        <p className="last-p">
                          <a to="https://www.google.rs/maps/place/Charging+Bull/@40.7055242,-74.0133806,20z/data=!4m5!3m4!1s0x0:0xc7db393ae1eff521!8m2!3d40.7055537!4d-74.0134436">
                            Broadway & Morris St, New York
                          </a>
                          <br />
                          <a to="https://www.google.rs/maps/place/Autoklub+Vsetin+Czechia/@28.0903625,-80.5700215,17z/data=!3m1!4b1!4m5!3m4!1s0x88de116ac5f97e1b:0xbcd6048a3d229c61!8m2!3d28.0903625!4d-80.5678328">
                            123 6th St. Melbourne, FL
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edgtf-column-content edgtf-grid-col-5 col-12">
                <div
                  id="custom_html-2"
                  className="widget_text widget edgtf-footer-column-4 widget_custom_html"
                >
                  <h5 className="edgtf-widget-title mb-0">FOLLOW US</h5>
                  <div className="textwidget custom-html-widget">
                    <p className="mb-0 text-white">
                      For all the latest news and updates, follow us on
                    </p>
                  </div>
                  <div className="widget edgtf-social-icons-group-widget">
                    {' '}
                    <Link
                      className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                      style={{ margin: '6px 8px 0 0px' }}
                      to="https://www.instagram.com/partypass1/"
                      target="_blank"
                    >
                      <span className="edgtf-social-icon-widget social_instagram_circle"></span>{' '}
                    </Link>
                    <Link
                      className="edgtf-social-icon-widget-holder edgtf-icon-has-hover d-none"
                      style={{ margin: '6px 8px 0 8px' }}
                      to="https://www.facebook.com/QodeInteractive/"
                      target="_blank"
                    >
                      <span className="edgtf-social-icon-widget social_facebook_circle"></span>{' '}
                    </Link>
                    <Link
                      className="edgtf-social-icon-widget-holder edgtf-icon-has-hover d-none"
                      style={{ margin: '6px 8px 0 8px' }}
                      to="https://plus.google.com/"
                      target="_blank"
                    >
                      <span className="edgtf-social-icon-widget social_googleplus_circle"></span>{' '}
                    </Link>
                    <Link
                      className="edgtf-social-icon-widget-holder edgtf-icon-has-hover d-none"
                      style={{ margin: '6px 8px 0 8px' }}
                      to="https://twitter.com/QodeInteractive"
                      target="_blank"
                    >
                      <span className="edgtf-social-icon-widget social_twitter_circle"></span>{' '}
                    </Link>
                    <Link
                      className="edgtf-social-icon-widget-holder edgtf-icon-has-hover d-none"
                      style={{ margin: '6px 8px 0 8px' }}
                      to="https://www.youtube.com/QodeInteractiveVideos"
                      target="_blank"
                    >
                      <span className="edgtf-social-icon-widget social_youtube_circle"></span>{' '}
                    </Link>
                    <Link
                      className="edgtf-social-icon-widget-holder edgtf-icon-has-hover d-none"
                      style={{ margin: '6px 8px 0 8px' }}
                      to="https://vimeo.com/"
                      target="_blank"
                    >
                      <span className="edgtf-social-icon-widget social_vimeo_circle"></span>{' '}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edgtf-footer-bottom-holder ">
          <div className="edgtf-footer-bottom-inner edgtf-grid">
            <div className="edgtf-grid-row content-center">
              <div className="edgtf-grid-col-7 ps-2">
                <div
                  id="media_image-2"
                  className="widget edgtf-footer-bottom-column-1 widget_media_image text-lg-start text-center"
                >
                  <div className="edgtf-logo-wrapper">
                    <a to="/">
                      <img
                        width="40"
                        height="40"
                        alt="logo"
                        src="../images/emblem.png"
                      />
                      PartyPass
                    </a>
                  </div>
                </div>
              </div>
              <div className="edgtf-grid-col-5 d-none">
                <div className="widget edgtf-social-icons-group-widget text-align-center">
                  <a
                    className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                    to="https://www.instagram.com/qodeinteractive/"
                    target="_blank"
                    style={{ margin: '6px 8px 0px' }}
                  >
                    <span className="edgtf-social-icon-widget social_instagram_circle"></span>
                  </a>
                  <a
                    className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                    to="https://www.facebook.com/QodeInteractive/"
                    target="_blank"
                    style={{ margin: '6px 8px 0px' }}
                  >
                    <span className="edgtf-social-icon-widget social_facebook_circle"></span>
                  </a>
                  <a
                    className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                    to="https://plus.google.com/"
                    target="_blank"
                    style={{ margin: '6px 8px 0px' }}
                  >
                    <span className="edgtf-social-icon-widget social_googleplus_circle"></span>
                  </a>
                  <a
                    className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                    to="https://twitter.com/QodeInteractive"
                    target="_blank"
                    style={{ margin: '6px 8px 0px' }}
                  >
                    <span className="edgtf-social-icon-widget social_twitter_circle"></span>
                  </a>
                  <a
                    className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                    to="https://www.youtube.com/QodeInteractiveVideos"
                    target="_blank"
                    style={{ margin: '6px 8px 0px' }}
                  >
                    <span className="edgtf-social-icon-widget social_youtube_circle"></span>
                  </a>
                  <a
                    className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                    to="https://vimeo.com/"
                    target="_blank"
                    style={{ margin: '6px 8px 0px' }}
                  >
                    <span className="edgtf-social-icon-widget social_vimeo_circle"></span>
                  </a>
                </div>
              </div>
              <div className="edgtf-grid-col-5">
                <div className="widget edgtf-separator-widget">
                  <div className="edgtf-separator-holder clearfix edgtf-separator-center edgtf-separator-normal">
                    <div
                      className="edgtf-separator"
                      style={{
                        borderColor: 'rgba(255, 255, 255, 0)',
                        borderStyle: 'solid',
                        marginTop: '-8px',
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  id="text-6"
                  className="widget edgtf-footer-bottom-column-3 widget_text"
                >
                  <div className="textwidget text-lg-start text-center">
                    <p className="text-white">
                      © 2024{' '}
                      <a
                        rel="noopener"
                        to="https://qodeinteractive.com/"
                        target="_blank"
                      >
                        PartyPass
                      </a>
                      , All Rights Reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

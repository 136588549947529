import React from 'react';
import Header from '../Home/Components/Header';
import Footer from '../Home/Components/Footer';

const TermsConditions = () => {
  return (
    <>
      <div className="edgtf-woocommerce-page edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />

            {/* new html start */}
            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix pt-3">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="pb-4 ">
                            <h5>Welcome to Party Pass LLC!</h5>
                            <p>
                              <span className="ff-b">Party Pass LLC, </span>a
                              Delaware corporation (together with its
                              affiliates, agents, representatives, consultants,
                              employees, officers, and directors – “Party Pass
                              LLC,” “we,” “us,” and/or “our”) provides the
                              ticketing platform located at
                              <a
                                href="/"
                                className="text-decoration-underline px-2"
                              >
                                {' '}
                                https://partypass.vip/
                              </a>{' '}
                              (collectively with other Party Pass LLC platforms
                              or websites, the “Site”) and the mobile
                              application (“App”) that allows users to offer,
                              sell, or buy event tickets or third-party services
                              and enables curators to manage marketing,
                              ticketing, attendance, and payment collection
                              (collectively, such services and related Party
                              Pass LLC products or services, including any new
                              features and applications, together with the Site
                              and App, the “Services”). Portions of the Services
                              are publicly available to all visitors to the Site
                              (the “Visitors”), whereas other portions of the
                              Services are available only to registered users
                              with an Account on the Services (collectively, the
                              “Members”). These Terms of Service (“Agreement”)
                              apply to both Visitors and Members (collecti
                            </p>
                            <div className="b-border">
                              <h5>
                                BY CLICKING THE “AGREE” BUTTON WHEN CREATING AN
                                ACCOUNT OR LOGGING IN, YOU:
                              </h5>
                              <ol className="ps-md-4">
                                <li>
                                  Acknowledge that you have read and understand
                                  this Agreement and the Privacy Policy;
                                </li>
                                <li>
                                  Represent that you have the authority and are
                                  fully able and competent to enter this
                                  Agreement on behalf of yourself or an entity;
                                </li>
                                <li>
                                  Represent that you are of legal age to enter
                                  into a binding agreement; and
                                </li>
                                <li>
                                  Accept this Agreement and agree that you are
                                  legally bound by its terms and the Privacy
                                  Policy.
                                </li>
                              </ol>
                              <p>
                                If you do not agree to these terms, do not use
                                the Services.
                              </p>
                            </div>
                            <div className="b-border">
                              <h5>Party Pass LLC Account Creation</h5>
                              <p>
                                Creating a Party Pass LLC Account: In order to
                                use the Services as a Member, you are required
                                to create a Party Pass LLC account (“Account”,
                                as further defined below). You must be at least
                                eighteen (18) years of age, or the age of
                                majority in your applicable state, to register
                                an Account; otherwise, you may only use the
                                Services with permission from, and under the
                                supervision of, your parent or guardian. You
                                represent that the information in your Account,
                                and any other information you otherwise provide
                                to us, is accurate, current, and complete, and
                                agree to update it and keep it accurate,
                                current, and complete. We reserve the right to
                                suspend or terminate your Account or your access
                                to the Services if any information provided to
                                us proves to be untrue, inaccurate, not current,
                                or incomplete. It shall be a violation of this
                                Agreement to (a) submit inauthentic information
                                for Account registration or maintenance, (b)
                                create or control more than one Account without
                                our express written authorization (including,
                                but not limited to, using a name that is not
                                yours, using a temporary or secondary email
                                address, or providing any other falsified
                                information), or (c) allow any other person to
                                use your Account to participate in or otherwise
                                use the Services. If you are a User seeking to
                                use the Services to manage marketing, ticketing,
                                attendance, or payment collection of your events
                                or services (collectively, “Curators”),
                                additional terms may apply to you.
                              </p>
                            </div>
                            <div className="b-border">
                              <h5>Account Activities</h5>
                              <p>
                                When you create an Account, you will be asked to
                                create a password. You are solely responsible
                                for maintaining the confidentiality of your
                                Account and password and for restricting access
                                to your computer/mobile device, and you agree to
                                accept responsibility for all activities,
                                charges (if applicable), and damages that occur
                                under your Account. If you discover any
                                unauthorized use of your Account, or other known
                                Account-related security breach, you must report
                                it to us immediately. You agree that you are
                                responsible for anything that happens through
                                your Account until you close your Account or
                                prove that your Account security was compromised
                                due to no fault of your own. We cannot and will
                                not be liable for any loss or damage arising
                                from your failure to comply with this section.
                              </p>
                            </div>
                            <div className="b-border">
                              <h5>Curator Obligations</h5>
                              <p>
                                Members who organize and promote events and
                                offer, sell or otherwise distribute tickets to
                                events (“Events”) through the Services are
                                referenced herein as Curators. Curator
                                represents and warrants that it (a) is
                                authorized to promote the Event through the
                                Services, that it will obtain all applicable
                                licenses, permits, and authorizations for such
                                Event (“Authorizations”) prior to offering,
                                selling, or distributing such Event tickets
                                through the Services, and that it shall provide
                                evidence of such Authorizations promptly upon
                                Party Pass LLC’s reasonable request from time to
                                time; (b) will comply with all applicable laws,
                                rules, or regulations related to Curator’s
                                promotion or hosting of such Event, including
                                but not limited to enforcement of any applicable
                                venue rules or identity verification of Event
                                attendees; promoting or hosting such Event; and
                                (c) that each of Curator’s Event listings is
                                accurate, current, complete, and not misleading
                                or otherwise deceptive. No Event tickets sold
                                through the Services will be delivered
                                separately or require an additional purchase,
                                for example, via a third-party website or other
                                channel. Curator understands and agrees that it
                                is solely responsible for its Events and the
                                Event tickets that it makes available or
                                distributes through the Services, including
                                posting or making available any applicable terms
                                and conditions regarding such Events or Event
                                tickets and that it is solely responsible for
                                any fees or costs incurred in connection with,
                                and for paying any applicable income, sales, or
                                other taxes that Curator may be subject to, as a
                                result of using the Services.
                              </p>
                            </div>
                            <div className="b-border">
                              <h5>Consumer Obligations</h5>
                              <p>
                                Members who purchase Event tickets from Curators
                                from Party Pass LLC through the Services are
                                referenced herein as “Consumers.” Consumer
                                understands and agrees that (a) Party Pass LLC
                                does not sell, and is not responsible for, the
                                Events promoted on the Services or the Event
                                tickets made available for purchase on the
                                Services and is merely a content host for such
                                Events; (c) Events made available through the
                                Services may be subject to the applicable terms
                                and conditions as stated by the Curator, and
                                Consumer is responsible for complying with such
                                Event terms and conditions. Consumer is
                                responsible for payment of all fees and charges
                                (such as taxes, if applicable and Party Pass LLC
                                processing fees for the transaction) for
                                purchases made on the Services through
                                Consumer’s Account, and for providing and
                                maintaining current and accurate payment details
                                in Consumer’s Account.
                              </p>
                            </div>
                            <div className="b-border">
                              <h5>
                                User Acknowledgment of Marketplace Platform;
                                Refund Policy
                              </h5>
                              <p>
                                Party Pass LLC is not responsible for any loss
                                or damage arising out of any decisions
                                ultimately made or implemented based on a User’s
                                use of the Services, including but not limited
                                to losses or damages relating to attendance at
                                any Events. Party Pass LLC has no control over
                                and does not guarantee the pricing, existence,
                                quality, safety or legality of any Events or
                                Event tickets, including any related User
                                Content, promoted on the Services. All purchases
                                made on the Services are subject to the refund
                                policies of the applicable Curator and/or Event.
                                Consumers may be able to request refunds under
                                certain circumstances if permitted by the
                                applicable Curator and/or Event, however Party
                                Pass LLC processing fees are non-refundable and
                                will not be included in any refunds processed on
                                the Services, regardless of the reason for the
                                refund. Consumers may request a refund by
                                contacting the Curator of an applicable Event or
                                contacting us at support@partypass.vip to be put
                                in contact with such Curator.
                              </p>
                            </div>
                            <div className="b-border">
                              <h5>User Interactions</h5>
                              <p>
                                Subject to the terms of the Party Pass LLC
                                Privacy Policy, your direct interactions (if
                                any) with other Users, including the purchase
                                of, sale of, payment for, and delivery of goods
                                or services, and any other terms, conditions,
                                warranties, or representations associated with
                                such dealings, are solely between you and that
                                individual User. Like with any web-based
                                interaction, we suggest that you use caution and
                                good judgment. Except as otherwise specifically
                                stated herein, Party Pass LLC is not involved in
                                any actual transactions made through the
                                Services. Party Pass LLC is not the agent or
                                representative of any User for any purpose
                                whatsoever and that it is not responsible for
                                any loss or damage incurred as the result of any
                                such dealings or with respect to any other
                                User’s use or disclosure of your personally
                                identifiable information. For details about our
                                information collection practices, please see our
                                Privacy Policy. IF THERE IS A DISPUTE BETWEEN
                                YOU AND ANY THIRD PARTY (INCLUDING, WITHOUT
                                LIMITATION, ANY MEMBER OR VISITOR OF THE
                                SERVICE), PARTY PASS LLC IS UNDER NO OBLIGATION
                                TO BECOME INVOLVED, AND YOU HEREBY RELEASE PARTY
                                PASS LLC FROM ANY CLAIMS, DEMANDS, OR DAMAGES OF
                                ANY KIND AND OF ANY NATURE ARISING OUT OF OR
                                RELATING TO ANY SUCH DISPUTE.
                              </p>
                              <h5>
                                Use of the Services; Reservation of Rights
                              </h5>
                              <p>
                                <span className="ff-b">License Grant.</span>{' '}
                                Subject to the terms of this Agreement, Party
                                Pass LLC grants you a personal, limited,
                                non-exclusive, and nontransferable license to
                                access and use the Services strictly in
                                accordance with this Agreement and all
                                applicable laws, rules, and regulations. You are
                                responsible for all of your activity in
                                connection with the Services.
                              </p>
                              <p>
                                <span className="ff-b">
                                  Reservation of Rights.
                                </span>{' '}
                                Party Pass LLC reserves the right to modify,
                                suspend or discontinue all or any aspect of the
                                Services to anyone for any reason at our sole
                                discretion, with or without any notice. Party
                                Pass LLC reserves the right to modify, suspend,
                                or discontinue the Services (including, but not
                                limited to, the availability of any feature,
                                database, or content), whether temporarily or
                                permanently at any time for any reason. You
                                agree that Party Pass LLC shall not be liable to
                                you or to any third party for any modification,
                                suspension, or discontinuation of the Services.
                                We may also impose limits on certain features
                                and services or restrict your access to parts or
                                all of the Services without notice or liability.
                                Party Pass LLC may, in its sole discretion: (a)
                                cancel unconfirmed Accounts, duplicate Accounts,
                                or Accounts that have been inactive for a
                                substantial period of time; (b) delay, refuse to
                                display, or remove content or listings; (c)
                                remove any special status associated with an
                                Account, and (d) take technical and/or legal
                                steps to limit or prevent any User’s use of the
                                Services, including imposing limits on certain
                                features of the Services or restricting access
                                to parts or all of the Services, in each case
                                without notice or liability.
                              </p>
                              <h5>Prohibited Conduct</h5>
                              <p>
                                You understand and agree that you will not use
                                the Services to engage in the prohibited conduct
                                below:
                              </p>
                              <ul className="ps-5 listing-dd">
                                <li>
                                  You shall not use the Services for any illegal
                                  or fraudulent purpose, or in violation of any
                                  local, state, national, or international law,
                                  including, without limitation, laws governing
                                  intellectual property and other proprietary
                                  rights, data protection and privacy;
                                </li>
                                <li>
                                  You shall not use the Services for purposes of
                                  competitive analysis, the development of a
                                  competing product or service, or any other
                                  purpose that is to our commercial
                                  disadvantage;
                                </li>
                                <li>
                                  You shall not submit information or
                                  documentation to the Services that pertains or
                                  belongs to any other party;
                                </li>
                                <li>
                                  You shall not post, store, send, transmit, or
                                  disseminate any information or material which
                                  infringes any patents, trademarks, trade
                                  secrets, copyrights, or any other proprietary
                                  or intellectual property rights;
                                </li>
                                <li>
                                  You shall not use the Services in any way that
                                  (i) posts, lists, or uploads content that is
                                  false, inaccurate, misleading, deceptive; (ii)
                                  contains any threat of violence to others;
                                  (iii) is in furtherance of illegal activities;
                                  (iv) is harassing, hateful, libelous,
                                  defamatory, abusive, or constitutes spam; or
                                  (v) is pornographic, predatory, sexually
                                  graphic, racist, offensive, harmful to a
                                  minor, or would otherwise violate the rights
                                  of any third party or give rise to civil or
                                  criminal liability;
                                </li>
                                <li>
                                  You shall not attempt to use any method to
                                  gain unauthorized access to any features of
                                  the Services;
                                </li>
                                <li>
                                  You shall not directly or indirectly decipher,
                                  decompile, remove, disassemble, reverse
                                  engineer, or otherwise attempt to derive any
                                  source code or underlying ideas or algorithms
                                  of any part of the Services, security-related
                                  features of the Services, features that
                                  prevent or restrict use or copying of any
                                  content accessible through the Services, or
                                  features that enforce limitations on use of
                                  the Services, except to the extent applicable
                                  laws specifically prohibit such restriction;
                                </li>
                                <li>
                                  You shall not directly or indirectly modify,
                                  translate, or otherwise create derivative
                                  works of any part of the Services, nor
                                  republish, sell, rent, or sublicense,
                                  reproduce, duplicate, or copy material from
                                  the Services;
                                </li>
                                <li>
                                  You shall not, without prior written approval
                                  from Party Pass LLC, create frames around our
                                  Web pages or use other techniques that alter
                                  in any way the visual presentation or
                                  appearance of our Site or Services;
                                </li>
                                <li>
                                  You shall not directly or indirectly license,
                                  copy, sell, rent, lease, distribute, or
                                  otherwise transfer any of the rights that you
                                  receive hereunder or commercially exploit the
                                  Services, in whole or in part;
                                </li>
                                <li>
                                  You shall not harvest or collect information
                                  about other Users without their consent;
                                </li>
                                <li>
                                  You shall not directly or indirectly take any
                                  action that constitutes unsolicited or
                                  unauthorized advertising or promotional
                                  material or any junk mail, spam, or chain
                                  letters; contains software viruses or any
                                  other computer codes, files, or programs that
                                  are designed or intended to disrupt, damage,
                                  limit, or interfere with the proper function
                                  of any software, hardware, or
                                  telecommunications equipment or to damage or
                                  obtain unauthorized access to any system,
                                  data, password, or other information of Party
                                  Pass LLC or any third party; or that
                                  impersonates any person or entity, including
                                  any employee or representative of Party Pass
                                  LLC;
                                </li>
                                <li>
                                  You shall not directly or indirectly take any
                                  action that imposes or may impose (as
                                  determined by Party Pass LLC in its sole
                                  discretion) an unreasonable or
                                  disproportionately large load on Party Pass
                                  LLC’s or its third-party providers’
                                  infrastructure; interfere or attempt to
                                  interfere with the proper working of the
                                  Services or any activities conducted on the
                                  Service; run any form of auto-responder or
                                  “spam” on the Services; or use automated means
                                  (including but not limited to scripts,
                                  third-party tools, bots, or web scrapers) to
                                  interact with the Services in any way; and
                                </li>
                                <li>
                                  You shall not create a false identity on the
                                  Services, misrepresent your identity,
                                  impersonate any person or entity (including
                                  any employee or representative of Party Pass
                                  LLC), create, use, or attempt to use an
                                  Account for anyone other than you, or sell or
                                  otherwise transfer your Account.
                                </li>
                              </ul>
                              <p>
                                If for any reason we determine that you have
                                failed to follow these rules, we reserve the
                                right to prohibit any and all current or future
                                use of the Services by you. If we have reason to
                                suspect, or learn that anyone is violating this
                                Agreement, we may investigate and/or take legal
                                action as necessary, including bringing a
                                lawsuit for damages caused by the violation. We
                                reserve the right to investigate and take
                                appropriate legal action, including without
                                limitation, cooperating
                              </p>
                              <p>
                                with and assisting law enforcement or government
                                agencies in any resulting investigations of
                                illegal conduct.
                              </p>
                              <h5>Availability of the Services</h5>
                              <p>
                                <span className="ff-b">Availability</span>. You
                                acknowledge that there may be interruptions in
                                service or events that are beyond our control.
                                While we use reasonable efforts to keep the
                                Services accessible, the Services may be
                                unavailable from time to time for any reason
                                including, without limitation, system downtime
                                for routine maintenance. You further understand
                                that there may be interruptions in service or
                                events on third-party sites that may affect your
                                use of the Services and that are beyond our
                                control to prevent or correct. Accordingly, we
                                cannot accept any responsibility for any
                                connectivity issues that you may experience when
                                using the Site or Services or for any loss of
                                material, data, transactions, or other
                                information caused by system outages, whether
                                planned or unplanned. You hereby agree that we
                                cannot be held liable to you or any third party
                                should we exercise our right to modify, suspend,
                                or discontinue the Services.
                              </p>
                              <h5>User Content Guidelines</h5>
                              <p>
                                <span className="ff-b">User Content.</span>{' '}
                                Users of the Services may have the ability to
                                contribute, add, create, submit, distribute,
                                facilitate the distribution of, collect, post,
                                or otherwise make accessible certain content
                                through submission of text, photographs, user
                                videos, electronic files, and software code or
                                license keys, or other information in order to
                                use, or continue using, the Services (“User
                                Content”). You understand and agree that you are
                                responsible for whatever material you submit,
                                and you, not Party Pass LLC, have full
                                responsibility for your User Content and
                                Feedback (as defined below), including its
                                legality, reliability, accuracy,
                                appropriateness, originality, and copyright.
                              </p>
                              <p>
                                <span className="ff-b">
                                  User Content License.
                                </span>{' '}
                                Party Pass LLC does not claim ownership of any
                                User Content. By posting User Content to the
                                Service, you grant us and our service providers
                                and business partners a nonexclusive,
                                royalty-free, perpetual, irrevocable,
                                sub-licensable, and transferable (in whole or in
                                part) worldwide license to use, modify, publicly
                                display, reproduce, and distribute such User
                                Content on and through the Service, including in
                                connection with the development, production,
                                distribution and/or exploitation (including
                                marketing and promotion) of Party Pass LLC
                                generally, unless otherwise prohibited by law.
                                You agree that this license includes the right
                                for us to make your User Content available to
                                other users of the Service, subject to this
                                Agreement and the Party Pass LLC Privacy Policy.
                              </p>
                              <p>
                                <span className="ff-b">
                                  User Feedback on the Services.
                                </span>{' '}
                                We, including third-party partners, may ask you
                                for Feedback (as defined below) on your
                                experience with the Services. We shall become
                                the owner of any reviews, comments, suggestions,
                                or other feedback regarding the Services posted
                                to the Services, Party Pass LLC social media
                                pages, or other third-party sites (collectively,
                                “Feedback”). Without limitation, we will have
                                exclusive ownership of all present and future
                                existing rights to the Feedback of every kind
                                and nature everywhere and will be entitled to
                                use the Feedback for any commercial or other
                                purpose whatsoever, including to advertise and
                                promote Party Pass LLC, without compensation to
                                you or any other person sending the
                              </p>
                              <p>
                                Feedback. You specifically waive any “moral
                                rights” in and to the Feedback. You agree that
                                any Feedback you submit to us will not contain
                                any information or ideas that you consider to be
                                confidential or proprietary.
                              </p>
                              <p>
                                <span className="ff-b">
                                  User Content Warranties.
                                </span>{' '}
                                To the extent that you decide to post any User
                                Content or Feedback on the Services or on Party
                                Pass LLC social media pages, you represent and
                                warrant to us that (a) you own the User Content,
                                or you otherwise have the legal right to use it
                                and you have received all necessary permissions,
                                clearances from, or are authorized by, the owner
                                of any part of the content to submit it to the
                                Services; (b) your User Content or Feedback will
                                not contain third-party copyrighted material, or
                                material that is subject to other third-party
                                proprietary rights
                              </p>
                              <h5>Party Pass LLC Intellectual Property</h5>
                              <p>
                                <span className="ff-b">
                                  Party Pass LLC Content.
                                </span>{' '}
                                Through the Services, we may make accessible
                                various content, including, but not limited to,
                                videos, photographs, images, artwork, graphics,
                                audio clips, comments, data, text, software,
                                scripts, campaigns, other material and
                                information, and associated trademarks and
                                copyrightable works (collectively, “Party Pass
                                LLC Content”).
                              </p>
                              <p>
                                <span className="ff-b">
                                  Party Pass LLC Intellectual Property Rights.
                                </span>
                                The Services and Party Pass LLC Content are
                                protected in many ways, including copyrights,
                                trademarks, service marks, and other rights and
                                laws. You agree to respect all legal notices,
                                information, and restrictions contained in any
                                content accessed through the Services, including
                                in any Party Pass LLC Content. You also agree
                                not to change, translate, or otherwise create
                                derivative works based off Party Pass LLC
                                Content. All other User Content viewed through
                                the Services is the property of its respective
                                owner. You have a limited, revocable,
                                non-exclusive, non-transferable license to use
                                the Services and Party Pass LLC Content solely
                                for legally permitted activities related to our
                                Services as outlined in this Agreement.
                              </p>
                            </div>
                            <div className="b-border">
                              <h5>
                                Copyright Policy and Notices of Intellectual
                                Property Violations
                              </h5>
                              <p>
                                <span className="ff-b">Copyright Policy.</span>{' '}
                                Party Pass LLC complies with the Digital
                                Millennium Copyright Act (DMCA). We will remove
                                infringing materials in accordance with the DMCA
                                if properly notified that Content infringes
                                copyright. If you believe that your work has
                                been copied in a way that constitutes copyright
                                infringement, please notify the Party Pass LLC
                                Copyright Agent by email at
                                support@partypassllc.com or by mail to the
                                address in the Contact section. Please do not
                                send notices or inquiries about anything other
                                than alleged copyright infringement or other
                                intellectual property claims to our Agent for
                                Notice. Your email must contain the following
                                information:
                              </p>
                              <ol className="ps-4">
                                <li>
                                  An electronic or physical signature of the
                                  person authorized to act on behalf of the
                                  owner of the copyright interest;
                                </li>
                                <li>
                                  Information reasonably sufficient to permit us
                                  to contact you, such as an address, telephone
                                  number, and, if available, an e-mail address;
                                </li>
                                <li>
                                  A description of the copyrighted work that you
                                  claim has been infringed;
                                </li>
                                <li>
                                  A description of where the material that you
                                  claim is infringing is located on the
                                  Services, sufficient for us to locate the
                                  material;
                                </li>
                                <li>
                                  Your address, telephone number, and email
                                  address;
                                </li>
                                <li>
                                  A statement by you that you have a good faith
                                  belief that the disputed use is not authorized
                                  by the copyright owner, its agent, or the law;
                                  and
                                </li>
                                <li>
                                  A statement by you that the information in
                                  your notice is accurate and, under penalty of
                                  perjury, that you are the copyright owner or
                                  authorized to act on the copyright owner’s
                                  behalf.
                                </li>
                              </ol>
                              <p>
                                If you fail to comply with these notice
                                requirements, your notification may not be
                                valid. Under the Copyright Act, any person who
                                knowingly materially misrepresents that material
                                is infringing or was removed or disabled by
                                mistake or misidentification may be subject to
                                liability.
                              </p>
                              <p>
                                In accordance with the Digital Millennium
                                Copyright Act, we have adopted a policy of, in
                                appropriate circumstances, terminating User
                                Accounts that are repeat infringers of the
                                intellectual property rights of others. We may
                                also terminate User Accounts even based on a
                                single infringement.
                              </p>
                              <p>
                                <span className="ff-b">Counter-Notice.</span> If
                                you believe that your User Content that was
                                removed (or to which access was disabled) is not
                                infringing, or that you have the authorization
                                from the copyright owner, the copyright owner’s
                                agent, or pursuant to the law, to post and use
                                the material in your User Content, you may send
                                a written counter-notice containing the
                                following information to the Copyright Agent:
                              </p>
                              <ol>
                                <li>Your physical or electronic signature;</li>
                                <li>
                                  Identification of the User Content that has
                                  been removed or to which access has been
                                  disabled and the location at which the Content
                                  appeared before it was removed or disabled;
                                </li>
                                <li>
                                  A statement that you have a good faith belief
                                  that the User Content was removed or disabled
                                  as a result of mistake or a misidentification
                                  of the User Content; and
                                </li>
                                <li>
                                  Your name, address, telephone number, and
                                  email address, a statement that you consent to
                                  the jurisdiction of the federal court in New
                                  York City, New York, and a statement that you
                                  will accept service of process from the person
                                  who provided notification of the alleged
                                  infringement.
                                </li>
                              </ol>
                              <p>
                                If a counter-notice is received by the Copyright
                                Agent, we may send a copy of the counter-notice
                                to the original complaining party informing that
                                person that it may replace the removed User
                                Content or cease disabling it in 10 business
                                days. Unless the copyright owner files an action
                                seeking a court order against the User Content
                                provider, member or user, the removed User
                                Content may be replaced, or access to it
                                restored, in 10 to 14 business days or more
                                after receipt of the counter-notice, at our sole
                                discretion.
                              </p>
                            </div>
                            <div className="b-border">
                              <h5>Sponsorships and Third-Party Sites</h5>
                              <p>
                                <span className="ff-b">Advertisements.</span>{' '}
                                The Services may contain third-party
                                advertisements, corporate sponsorships and/or
                                branded content (for example, from our marketing
                                or launch partners as may be designated on the
                                App or Site). The sponsors that provide these
                                advertisements or sponsorships are solely
                                responsible for ensuring that the materials
                                submitted for inclusion on the Services are
                                accurate and that they comply with all
                                applicable laws. We are not responsible for the
                                acts or omissions of any sponsor or advertiser.
                              </p>
                              <p>
                                <span className="ff-b">Third-Party Sites.</span>{' '}
                                The Services may permit you to link to other
                                websites or resources on the internet. Links on
                                the Services to third-party websites, if any,
                                are provided only as a convenience to you. If
                                you use these links, you will leave the
                                Services. The inclusion or integration of
                                third-party services or links does not imply
                                control of, endorsement by, or affiliation with
                                Party Pass LLC. Your dealings with third parties
                                are solely between you and such third parties.
                                You agree that we will not be responsible or
                                liable for any content, goods, or services
                                provided on or through these outside websites or
                                for your use or inability to use such websites.
                                You will use these links at your own risk
                              </p>
                              <p>
                                <span className="ff-b">
                                  Hyperlinking to Our Content.
                                </span>{' '}
                                The following organizations may link to our Site
                                without our prior written approval:
                              </p>
                              <ul className="listing-dd ps-5">
                                <li>Government agencies;</li>
                                <li>Search engines;</li>
                                <li>News organizations;</li>
                                <li>
                                  Online directory distributors when they list
                                  us in the directory may link to our Site in
                                  the same manner as they hyperlink to the
                                  websites of other listed businesses; and
                                </li>
                                <li>
                                  Systemwide Accredited Businesses except
                                  soliciting non-profit organizations, charity
                                  shopping malls, and charity fundraising groups
                                  which may not hyperlink to our Site.
                                </li>
                              </ul>
                              <p>
                                The above organizations may link to our home
                                page, or to Party Pass LLC publications or other
                                Site information so long as the link:
                              </p>
                              <ul className="listing-dd ps-5">
                                <li>(a) is not in any way misleading,</li>
                                <li>
                                  (b) does not falsely imply sponsorship,
                                  endorsement or approval of the linking party
                                  and its products or services, and
                                </li>
                                <li>
                                  (c) fits within the context of the linking
                                  party’s site.
                                </li>
                              </ul>
                              <p>
                                <span className="ff-b">
                                  Hyperlinking by Third Parties Requiring Party
                                  Pass LLC Approval.
                                </span>{' '}
                                The following organizations must obtain prior
                                written approval from Party Pass LLC before
                                linking to our Site:
                              </p>
                              <ul className="listing-dd ps-5">
                                <li>
                                  Commonly-known consumer and/or business
                                  information sources such as Chambers of
                                  Commerce, American Automobile Association,
                                  AARP and Consumers Union;
                                </li>
                                <li>Dot.com community sites;</li>
                                <li>
                                  Associations or other groups representing
                                  charities, including charity giving sites,
                                </li>
                                <li>Online directory distributors;</li>
                                <li>Internet portals;</li>
                                <li>
                                  Accounting, law and consulting firms whose
                                  primary clients are businesses; and
                                </li>
                                <li>
                                  Educational institutions and trade
                                  associations.
                                </li>
                                <p>
                                  If you are among the organizations listed in
                                  this Section 7.4 and are interested in linking
                                  to our Site, you must notify us prior to doing
                                  so by sending an email to
                                  avante@partypassllc.com. Please include your
                                  name, your organization name, contact
                                  information (such as a phone number and/or
                                  e-mail address) as well as the URL of your
                                  site, a list of any URLs from which you intend
                                  to link to our Site, and a list of the URL(s)
                                  on our site to which you would like to link.
                                  Allow 2-3 weeks for a response. We may approve
                                  such link requests in our sole discretion.
                                  Once approved by Party Pass LLC, the above
                                  organizations may link to our home page, or to
                                  Party Pass LLC publications or other Site
                                  information so long as the link:
                                </p>
                                <li>(a) is not in any way misleading,</li>
                                <li>
                                  (b) does not falsely imply sponsorship,
                                  endorsement or approval of the linking party
                                  and its products or services, and
                                </li>
                                <li>
                                  (c) fits within the context of the linking
                                  party’s site
                                </li>
                                <p>
                                  <span className="ff-b">
                                    Permitted Hyperlink Activities.
                                  </span>{' '}
                                  Approved organizations may hyperlink to our
                                  Site as follows:
                                </p>
                                <li>(a) by use of our corporate name;</li>
                                <li>
                                  (b) by use of the URL Web address being linked
                                  to; or
                                </li>
                                <li>
                                  (c) by use of any other description of our
                                  Site or material being linked to that makes
                                  sense within the context and format of content
                                  on the linking party’s site.
                                </li>
                              </ul>
                              <p>
                                Notwithstanding the foregoing, no use of Party
                                Pass LLC’s logo or other artwork is permitted
                                for linking absent an executed trademark license
                                agreement with Party Pass LLC. Party Pass LLC
                                reserves the right at any time and in its sole
                                discretion to request your removal of any and
                                all links to any part of our Site or Services.
                                You agree to immediately remove all such links
                                upon our request.
                              </p>
                              <h5>
                                Dispute Resolution and Arbitration Provision:
                                PLEASE READ THIS SECTION CAREFULLY – IT MAY
                                SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS,
                                INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT
                              </h5>
                              <p>
                                <span className="ff-b">
                                  Initial Dispute Resolution.
                                </span>{' '}
                                For any problem or dispute that you may have
                                with us, you understand and agree that you will
                                first give us an opportunity to resolve your
                                problem or dispute. In order to initiate this
                                dispute resolution process, you agree that you
                                shall first send us a written description of
                                your problem or dispute within thirty (30) days
                                of the occurrence of the event giving rise to
                                the problem or dispute by sending an email to:
                                support@partypassllc.com or by mail to the
                                address listed below. You then agree to
                                negotiate with us in good faith about your
                                problem or dispute for sixty (60) days. This
                                should lead to resolution, but if for some
                                reason your problem or dispute is not resolved
                                satisfactorily within sixty (60) days after our
                                receipt of your written description of it, you
                                agree to the arbitration provisions below.
                              </p>
                              <p>
                                <span className="ff-b">
                                  Binding Arbitration.
                                </span>{' '}
                                If the parties do not reach an agreed-upon
                                solution under the Initial Dispute Resolution
                                provision, then either party may initiate
                                binding arbitration as the sole means to resolve
                                claims, subject to the terms set forth below.
                                Specifically, all claims arising out of or
                                relating to this Agreement (including formation,
                                performance, and breach), the parties’
                                relationship with each other, and/or your use of
                                the Services shall be finally settled by binding
                                arbitration administered by the American
                                Arbitration Association in accordance with the
                                provisions of its Commercial Arbitration Rules
                                and the supplementary procedures for
                                consumer-related disputes of the American
                                Arbitration Association (the “AAA”), excluding
                                any rules or procedures governing or permitting
                                class actions. The Commercial Arbitration Rules
                                governing the arbitration may be accessed at
                                www.adr.org or by calling the AAA at
                                +1.800.778.7879. The arbitrator, and not any
                                federal, state, or local court or agency, shall
                                have exclusive authority to resolve all disputes
                                arising out of or relating to the
                                interpretation, applicability, enforceability,
                                or formation of this Agreement, including, but
                                not limited to, any claim that all or any part
                                of this Agreement are void or voidable, or
                                whether a claim is subject to arbitration. The
                                arbitrator shall be empowered to grant whatever
                                relief would be available in a court under law
                                or in equity. The arbitration rules also permit
                                you to recover attorney’s fees in certain cases.
                                The arbitrator’s award shall be written, and
                                binding on the parties and may be entered as a
                                judgment in any court of competent jurisdiction.
                                The parties understand that, absent this
                                mandatory provision, they would have the right
                                to sue in court and have a jury trial. They
                                further understand that, in some instances, the
                                costs of arbitration could exceed the costs of
                                litigation and the right to discovery may be
                                more limited in arbitration than in court.
                              </p>
                              <p>
                                <span className="ff-b">Location.</span> The
                                arbitration will take place in New York City,
                                New York.
                              </p>
                              <p>
                                <span className="ff-b">
                                  Class Action Waiver.
                                </span>{' '}
                                The parties further agree that any arbitration
                                shall be conducted in their individual
                                capacities only and not as a class action or
                                other representative action, and the parties
                                expressly waive their right to file a class
                                action or seek relief on a class basis. YOU AND
                                PARTY PASS LLC AGREE THAT EACH MAY BRING CLAIMS
                                AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
                                CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER
                                IN ANY PURPORTED CLASS OR REPRESENTATIVE
                                PROCEEDING. If any court or arbitrator
                                determines that the class action waiver set
                                forth in this paragraph is void or unenforceable
                                for any reason or that an arbitration can
                                proceed on a class basis, then the arbitration
                                provision set forth above shall be deemed null
                                and void in its entirety and the parties shall
                                be deemed to have not agreed to arbitrate
                                disputes.
                              </p>
                              <p>
                                <span className="ff-b">
                                  Exceptions – Litigation of Intellectual
                                  Property and Small Claims Court Claims.
                                </span>{' '}
                                Notwithstanding the parties’ decision to resolve
                                all disputes through arbitration, either party
                                may bring an action in state or federal court to
                                protect its intellectual property rights
                                (“intellectual property rights” means patents,
                                copyrights, moral rights, trademarks, and trade
                                secrets, but not privacy or publicity rights).
                                Either party may also seek relief in a small
                                claims court for disputes or claims within the
                                scope of that court’s jurisdiction.
                              </p>
                              <p>
                                <span className="ff-b">
                                  30-Day Right to Opt-Out.
                                </span>{' '}
                                You have the right to opt-out and not be bound
                                by the arbitration and class action waiver
                                provisions set forth above by sending written
                                notice of your decision to opt-out to us at
                                support@partypassllc.com. The notice must be
                                sent within thirty (30) days of your first use
                                of the Services, otherwise you shall be bound to
                                arbitrate disputes in accordance with the terms
                                of those paragraphs. If you opt-out of these
                                arbitration provisions, we also will not be
                                bound by them.
                              </p>
                              <p>
                                <span className="ff-b">
                                  Changes to this Section.
                                </span>{' '}
                                We will provide thirty (30) days’ notice of any
                                changes to this section. Changes will become
                                effective on the 30th day, and will apply
                                prospectively only to any claims arising after
                                the 30th day. The Agreement and the relationship
                                between you and PARTY PASS LLC shall be governed
                                by the laws of the State of New York without
                                regard to conflict of law provisions.
                              </p>
                            </div>
                            <div className="b-border">
                              <h5>Miscellaneous</h5>
                              <p>
                                <span className="ff-b">Assignment.</span> This
                                Agreement is personal to User, and you may not
                                assign, transfer, sub-license, sub-contract,
                                charge or otherwise encumber any of your rights
                                or obligations under this Agreement without the
                                prior written consent of PARTY PASS LLC. PARTY
                                PASS LLC may assign, transfer, or delegate any
                                of its rights and obligations hereunder without
                                your consent. Any attempted assignment in
                                violation of this Section 13.1 shall be null and
                                void.
                              </p>
                              <p>
                                <span className="ff-b">Entire Agreement.</span>{' '}
                                This Agreement, and all terms and policies
                                posted through our Services, including our
                                Privacy Policy and any applicable Supplemental
                                Terms, constitutes the entire agreement between
                                you and PARTY PASS LLC with respect to the
                                Services, and supersedes all prior or
                                contemporaneous understandings and agreements of
                                the parties, whether written or oral, with
                                respect to the Services.
                              </p>
                              <p>
                                <span className="ff-b">
                                  Geographic Restrictions.
                                </span>{' '}
                                The Services are based in the state of New York
                                in the United States and provided for access and
                                use only by persons located in the United
                                States. You acknowledge that you may not be able
                                to access the Services outside of the United
                                States and that access thereto may not be legal
                                by certain persons or in certain countries. If
                                you access the Services from outside the United
                                States, you are responsible for compliance with
                                local laws.
                              </p>
                              <p>
                                <span className="ff-b">
                                  Governing Law and Jurisdiction.
                                </span>{' '}
                                This Agreement is governed by and construed in
                                accordance with the internal laws of the State
                                of New York without giving effect to any choice
                                or conflict of law provision or rule. Any legal
                                suit, action, or proceeding arising out of or
                                related to this Agreement or the Application
                                shall be instituted exclusively in the federal
                                courts of the United States or the courts of the
                                State of New York in each case located in New
                                York City, New York. You waive any and all
                                objections to the exercise of jurisdiction over
                                you by such courts and to venue in such courts.
                              </p>
                              <p>
                                <span className="ff-b">Modification.</span> We
                                reserve the right, at our sole discretion, to
                                amend, modify or replace this Agreement,
                                including the Privacy Policy or any Supplemental
                                Terms, at any time. The most current version of
                                this Agreement (with the revision date stated)
                                will be made available through the Site. In the
                                event that we make material changes to the
                                Agreement, we will notify you by displaying a
                                prominent notice on the Site or by sending an
                                email to the email address affiliated with your
                                Account. Updated versions of the Agreement will
                                never apply retroactively and the updated
                                Agreement will give the exact date they go into
                                effect. It is your responsibility to check the
                                Site periodically for changes to the Agreement.
                                Use of the Services by you following any
                                modification to the Agreement constitutes your
                                acceptance of the Agreement as modified. Without
                                limiting our ability to refuse, modify, or
                                terminate all or part of our Services, we may
                                also terminate this Agreement at any time for
                                any reason, at our sole discretion, by giving
                                notice of such termination.
                              </p>
                              <p>
                                <span className="ff-b">
                                  Relationship of the Parties.
                                </span>{' '}
                                Becoming a User of our Services does not create
                                an agency, employment, joint venture, franchise,
                                or partnership relationship between you and
                                PARTY PASS LLC.
                              </p>
                              <p>
                                <span className="ff-b">Severability.</span> If
                                any provision of this Agreement is illegal or
                                unenforceable under applicable law, the
                                remainder of the provision will be amended to
                                achieve as closely as possible the effect of the
                                original term and all other provisions of this
                                Agreement will continue in full force and
                                effect.
                              </p>
                              <p>
                                <span className="ff-b">
                                  Supplemental Terms.
                                </span>{' '}
                                Your use of the Services may be subject to
                                additional terms and conditions, such as a set
                                of terms that apply to a specific promotion or
                                line of services, which may be modified from
                                time to time (“Supplemental Terms”).
                                Supplemental Terms are in addition to, and shall
                                be deemed a part of, this Agreement. In the
                                event of a conflict between the Supplemental
                                Terms and this Agreement, the Supplemental Terms
                                shall prevail solely to the extent of such
                                conflict.
                              </p>
                              <p>
                                <span className="ff-b">Waiver.</span> No failure
                                to exercise, and no delay in exercising, on the
                                part of either party, any right or any power
                                hereunder shall operate as a waiver thereof, nor
                                shall any single or partial exercise of any
                                right or power hereunder preclude further
                                exercise of that or any other right hereunder.
                                In the event of a conflict between this
                                Agreement and any applicable purchase or other
                                terms, the terms of this Agreement shall govern.
                              </p>
                              <p>
                                <span className="ff-b">Contact.</span> If you
                                have any questions about this Agreement, or
                                feedback, comments, requests for technical
                                support, and other communications related to the
                                Services, please contact us at
                                support@partypassllc.com or at the address set
                                forth below.
                              </p>
                            </div>
                            <div className="b-border">
                              <p>
                                Here’s the revised version with “POSH” changed
                                to “Party Pass LLC”:
                              </p>
                            </div>
                            <div className="">
                              <h5>
                                Party Pass LLC Community Addendum (“Community
                                Terms”)
                              </h5>
                              <p>
                                This Party Pass LLC Community Addendum
                                (“Community Terms”) is an addendum to the
                                general terms and definitions contained in the
                                Party Pass LLC Terms of Service (“Agreement”)
                                and applies as Supplemental Terms for all
                                Curators or Users of our marketplace platform
                                located at https://partypassllc.com/community
                                (the “Party Pass LLC Community”). These
                                Community Terms apply to Users who use Party
                                Pass LLC Community to connect with other Users
                                in order to offer, promote, sell, or purchase
                                Freelancer Services as either Consumers or
                                Curators of such services. Capitalized terms not
                                defined here have the same definitions used in
                                the Agreement.
                              </p>
                              <h5>
                                Party Pass LLC Community Platform for
                                Freelancers and Consumers
                              </h5>
                              <p>
                                <span className="ff-b">General.</span> The Party
                                Pass LLC Community marketplace platform connects
                                certain Curators who promote their own services
                                (“Freelancers” and the services they offer
                                through Party Pass LLC Community, the
                                “Freelancer Services”) with Consumers who seek
                                to obtain and purchase Freelancer Services.
                              </p>
                              <p>
                                <span className="ff-b">
                                  User Acknowledgment of Marketplace Platform.
                                </span>
                                Users of the Services or Party Pass LLC
                                Community contract directly with other Users,
                                and Party Pass LLC shall not be a party to any
                                such contracts between Users. Party Pass LLC is
                                not an employer of, or affiliated with, any of
                                the Curators who may use portions of the
                                Services or Party Pass LLC Community. Party Pass
                                LLC is not responsible for any loss or damage
                                arising out of or related to any User’s use of
                                Party Pass LLC Community, including but not
                                limited to purchases made through the Services.
                                Party Pass LLC has no control over and does not
                                guarantee the pricing, existence, quality,
                                safety or legality of any Freelancer Services,
                                including any related User Content, promoted on
                                the Services. All purchases made on Party Pass
                                LLC Community are subject to the cancellation or
                                refund policies of the applicable Freelancer and
                                our current policies regarding cancellation
                                requests, payment, and support, which may be
                                found at https://partypassllc.com/tos.
                              </p>
                              <h5>Freelancer or Curator Obligations</h5>
                              <p>
                                <span className="ff-b">
                                  Party Pass LLC Community Profiles.
                                </span>{' '}
                                Freelancers may create profiles to advertise,
                                promote, and otherwise offer to perform the
                                Freelancer Services for Consumers on Party Pass
                                LLC Community. By using Party Pass LLC Community
                                as a Freelancer, you acknowledge and agree that
                                you:
                              </p>
                              <ul className="listing-dd ps-5">
                                <li>
                                  (a) must use your legal name and provide a
                                  current photograph of yourself for use on your
                                  Party Pass LLC Community profile;
                                </li>
                                <li>
                                  (b) shall respect the privacy (including
                                  without limitation private, family and home
                                  life), property, and data protection rights of
                                  Consumers and that you will not record
                                  (whether video or audio or otherwise) any
                                  performance of the Freelancer Services or any
                                  interaction by or with any Consumer in
                                  connection with Party Pass LLC Community
                                  without the prior written consent of each
                                  relevant Consumer, or Party Pass LLC, as
                                  applicable;
                                </li>
                                <li>
                                  (c) will act professionally and responsibly in
                                  your interactions and dealings with Consumers
                                  on Party Pass LLC Community, and will honor
                                  and fulfill your commitments to such
                                  Consumers, including by responding to
                                  communications promptly, performing the
                                  Freelancer Services as agreed between you and
                                  a Consumer, and providing timely, high-quality
                                  Freelancer Services to Consumers;
                                </li>
                                <li>
                                  (d) agree that all payments for Freelancer
                                  Services offered or sold through Party Pass
                                  LLC Community will be made or received solely
                                  through Party Pass LLC Community or applicable
                                  Party Pass LLC payment processors, and that
                                  additional terms and conditions of such
                                  payment processors may apply. You are solely
                                  responsible for the Freelancer Services that
                                  you promote, offer, or sell through Party Pass
                                  LLC Community, including posting or
                                  communicating any applicable terms and
                                  conditions to Consumers. You are solely
                                  responsible for any fees or costs incurred in
                                  connection with, and for paying any applicable
                                  income, sales, or other taxes that you may be
                                  subject to, as a result of your use of Party
                                  Pass LLC Community
                                </li>
                              </ul>
                              <p>
                                Curator Representations and Warranties. Curator
                                represents and warrants that at all times during
                                its use of the Services, it:
                              </p>
                              <ul className="listing-dd ps-5">
                                <li>
                                  (a) operates as a sole proprietor or an
                                  independent business owner of an entity in
                                  good standing;
                                </li>
                                <li>
                                  (b) is customarily engaged in an independently
                                  established business of the same nature as the
                                  Freelancer Services offered or performed for
                                  Consumers through Party Pass LLC Community;
                                </li>
                                <li>
                                  (c) will only offer or provide Freelancer
                                  Services for which Freelancer has the
                                  necessary skills and expertise to provide
                                  safely and competently, and that each of
                                  Freelancer’s listings or offerings will be
                                  accurate, current, complete, and not
                                  misleading or otherwise deceptive;
                                </li>
                                <li>
                                  (d) has the unrestricted right to perform the
                                  Freelancer Services in the applicable
                                  jurisdiction in which it will perform such
                                  services for Consumers;
                                </li>
                                <li>
                                  (e) has the right, authority, and capacity to
                                  offer and perform the Freelancer Services on
                                  behalf of itself, that it will obtain all
                                  applicable licenses, permits, and
                                  authorizations for such Freelancer Services
                                  (“Authorizations”) prior to offering, selling,
                                  or performing the Freelancer Services, and
                                  that it shall provide evidence of such
                                  Authorizations promptly upon Party Pass LLC’s
                                  reasonable request from time to time;
                                </li>
                                <li>
                                  (f) will comply with all applicable laws,
                                  rules, or regulations related to the offer,
                                  sale, or performance of the Freelancer
                                  Services;
                                </li>
                                <li>
                                  (g) it will obtain and maintain any and all
                                  required insurance policies for operation of
                                  its business or provision of the Freelancer
                                  Services; and
                                </li>
                                <li>
                                  (h) will not violate the terms and conditions
                                  of any other third-party agreements, nor
                                  violate the rights of any third parties, in
                                  its performance of the Freelancer Services or
                                  use of Party Pass LLC Community.
                                </li>
                              </ul>
                              <p>
                                <span className="ff-b">
                                  Additional Curator Obligations.
                                </span>{' '}
                                As a Curator, you are solely responsible for
                                obtaining general liability insurance policies
                                with coverage amounts sufficient to cover all
                                risks associated with your performance of the
                                Freelancer Services or hosting of an Event. You
                                shall provide the Freelancer Services under your
                                own name or business name only, and not under
                                that of Party Pass LLC or any third party. You
                                acknowledge and agree that your use of Party
                                Pass LLC Community creates a direct business
                                relationship between you and a Consumer only and
                                that Party Pass LLC is acting as the limited
                                payment collection agent solely for the purpose
                                of facilitating payment to you from Consumers
                                for the Freelancer Services that you provide to
                                such Consumers in accordance with the Agreement
                                and these Community Terms. You further
                                acknowledge and agree that you are free to offer
                                and provide your services elsewhere, including
                                through competing platforms. You may accept or
                                reject offers or requests from Consumers in your
                                sole discretion; however, if you accept a
                                Consumer as a client or otherwise contract with
                                them through Party Pass LLC Community, you are
                                expected to fulfill your contractual obligations
                                to such Consumer or otherwise comply with our
                                current policies regarding cancellation,
                                payment, and support.
                              </p>
                              <h5>Consumer Obligations</h5>
                              <p>Consumer understands and agrees that:</p>
                              <ul className="listing-dd ps-5">
                                <li>
                                  (a) Party Pass LLC does not direct or control
                                  any Freelancer or their Freelancer Services,
                                  nor does Party Pass LLC set their work
                                  locations, work hours, terms of work, or fees;
                                </li>
                                <li>
                                  (b) Freelancer Services made available through
                                  the Party Pass LLC Community marketplace may
                                  be subject to additional terms and conditions
                                  as stated by the Freelancer, and Consumer is
                                  responsible for complying with such terms and
                                  conditions; and
                                </li>
                                <li>
                                  (c) Consumer is responsible for payment of all
                                  fees and charges (such as taxes, if applicable
                                  and Party Pass LLC processing fees for the
                                  transaction) for Freelancer Services purchased
                                  through Consumer’s Account, and for providing
                                  and maintaining current and accurate payment
                                  details in Consumer’s Account
                                </li>
                              </ul>
                              <h5>
                                Party Pass LLC Fees for Curators; Payment Terms
                              </h5>
                              <p>
                                <span className="ff-b">Freelancer Fees.</span>{' '}
                                Freelancers are entitled to fees for any
                                applicable Freelancer Services sold to other
                                Users through the Party Pass LLC Services
                                (collectively, “Freelancer Fees”). By using the
                                Services, you acknowledge and agree that our
                                current policies regarding cancellation,
                                payment, and support shall apply, which may be
                                found at https://partypassllc.com/tos. The
                                purchasing Users are solely responsible for
                                payment of all Freelancer Fees owed, including
                                fees owed for Freelancer Services offered
                                through Party Pass LLC Community, and Party Pass
                                LLC is not obligated to compensate a Curator for
                                another User’s failure to pay Freelancer Fees.
                                By using the Services, you agree to consent to
                                the applicable terms and conditions of Party
                                Pass LLC’s payment processors, and you
                                understand and agree that Party Pass LLC has no
                                obligations, responsibilities, or liabilities to
                                any Curator or other party under your agreements
                                with such payment processors.
                              </p>
                              <p>
                                <span className="ff-b">
                                  Reservation of Rights.
                                </span>{' '}
                                For security concerns, Party Pass LLC reserves
                                the right in its sole and reasonable discretion
                                to validate the financial information associated
                                with any Account and/or temporarily disable a
                                Curator’s ability to withdraw fees from
                                Consumers to prevent fraudulent or illicit
                                activity, including but not limited to as a
                                result of security issues, improper behavior
                                reported by other Users, or associating multiple
                                Accounts to a single withdrawal provider. Party
                                Pass LLC may, but has no obligation to, place a
                                hold on any Curator Payment, process applicable
                                refunds to Consumers, either directly or through
                                its applicable payment processors and pursuant
                                to the Party Pass LLC Privacy Policy or any
                                applicable Supplemental Terms.
                              </p>
                              <p>
                                <span className="ff-b">Tax Reporting.</span>{' '}
                                Users of Party Pass LLC Community may be liable
                                for any taxes or similar charges (including VAT,
                                if applicable in the country where Freelancer
                                Services are performed) as may be required to be
                                collected and/or paid on the Freelancer Services
                                or other Consumer fees as provided under the
                                Agreement. The applicable laws, rules, or
                                regulations of certain jurisdictions may require
                                that we collect or report tax and/or revenue
                                information about you. By using the Services,
                                you agree that Party Pass LLC may issue receipts
                                or similar documentation on your behalf in order
                                to facilitate accurate tax reporting where
                                applicable.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* new html end */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;

import React, { useEffect, useState } from 'react';
import EventCard from './EventCard';
import Pagination from '../../../Components/Pagination';
import axiosInstance from '../../../../Utils/axiosInstance';
import { EVENTS_ENDPOINT } from '../../../../Utils/Endpoints';
import moment from 'moment';
import Logger from '../../../../Utils/Logger';

const EventList = () => {
  const [eventList, setEventList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Pagination
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchEvent();
  }, [currentPage]);

  const fetchEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENTS_ENDPOINT +
          `?event_start_from=${moment().unix()}&page=${currentPage}&per_page=${itemsPerPage}`
      );
      if (response.data.code === 200) {
        setEventList(response.data.data.data);
        setTotalItems(response.data.data.total);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {eventList?.length > 0 && (
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                {/* Events Listing START */}
                <div className="events-listing">
                  <var className="edgtf-st-tagline">Upcoming Events </var>
                  <div className="outer outer-new">
                    {!isLoading &&
                      eventList?.map((item, index) => (
                        <EventCard
                          event={item}
                          imageUser={
                            item?.banner?.fullUrl
                              ? item?.banner?.fullUrl
                              : '../images/thumbnail.png'
                          }
                          heading={item?.name}
                          text={item?.startDateTimeFormat}
                          key={index}
                        />
                      ))}
                  </div>
                  <div>
                    {totalItems > 10 && (
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                      />
                    )}
                  </div>
                </div>

                {/* Events Listing END */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventList;
